import React, { useState } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass/styled-components';
import { Input, Label, Textarea } from '@rebass/forms';
import { useMutation } from '@apollo/client';
import { navigate } from 'gatsby-link';
import Layout, { theme } from '../components/Layout';
import sendContactMessageMutation from '../graphql/mutations/sendContactMessage.graphql';

const labelStyle = {
  color: theme.color,
  fontSize: '20px',
  marginBottom: '10px',
};

const inputStyle = {
  color: theme.color,
  fontSize: '20px',
  fontFamily: 'Open Sans',
  border: '1px #ddd solid',
  resize: 'none',
  fontWeight: 300,
};

function getSubmitButtonProps(isSending, messageSent) {
  if (messageSent) {
    return { text: 'Wysłano', color: 'success' };
  }
  if (isSending) {
    return { text: 'Wysyłam...', color: 'primary' };
  }
  return { text: 'Wyślij', color: 'primary' };
}

const ContactPage = () => {
  const [isSending, setIsSending] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [sendMessage] = useMutation(sendContactMessageMutation, {
    onCompleted: () => {
      setMessageSent(true);
      setTimeout(() => {
        navigate('/');
        return null;
      }, 2000);
    },
    onError: () => {
      alert('Coś poszło nie tak, spróbuj później.');
    },
  });
  const onSubmit = (e) => {
    e.preventDefault();
    const { content, email } = e.target;
    if (!content.value || !email.value) {
      alert('Pola "email" i "treść maila" nie mogą być puste');
      return;
    }
    setIsSending(true);
    sendMessage({
      variables: { email: email.value, content: content.value },
    });
  };

  const buttonProps = getSubmitButtonProps(isSending, messageSent);
  return (
    <Layout>
      <Box variant={'wrapper'} as={'form'} onSubmit={onSubmit}>
        <Flex width={[1, 2 / 3, null]} flexDirection={'column'}>
          <Heading fontFamily={'Oswald'} fontSize={36} color={'text'}>
            Formularz kontaktowy
          </Heading>
          <Box marginTop={20}>
            <Label htmlFor="email" sx={labelStyle}>
              Podaj swój e-mail
            </Label>
            <Input
              disabled={isSending || messageSent}
              type={'email'}
              id="email"
              name="email"
              placeholder="jan.kowalski@poczta.pl"
              sx={inputStyle}
            />
          </Box>
          <Box marginTop={20}>
            <Label htmlFor="content" sx={labelStyle}>
              Treść maila
            </Label>
            <Textarea
              sx={inputStyle}
              id="content"
              name="content"
              height={200}
              placeholder="Treść wiadomości"
              disabled={isSending || messageSent}
            />
          </Box>
          <Button
            width={1 / 5}
            alignSelf={'center'}
            backgroundColor={buttonProps.color}
            marginTop={20}
            type={'submit'}
            disabled={isSending || messageSent}
          >
            <Text fontSize={20} color={'muted'}>
              {buttonProps.text}
            </Text>
          </Button>
          <Box mt={20}>
            <Text fontSize={18} color={'text'}>
              Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych
              osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z
              04.05.2016) informujemy, iż:
              <ol>
                <li>
                  Administratorem Pani/Pana danych osobowych jest ODN "Prolegis"
                  Elżbieta Linowsk z siedzibą w Białymstoku przy Al. Jana Pawła
                  II 59/43.
                </li>
                <li>
                  Pani/Pana dane osobowe przetwarzane będą w celu realizacji
                  zamówienia - na podstawie Art. 6 ust. 1 lit. b ogólnego
                  rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia
                  2016 r.
                </li>
                <li>
                  Odbiorcami Pani/Pana danych osobowych będą podmioty
                  uczestniczące w realizacji zamówienia - ECRK Lex s.c., EduLex,
                  ODN "Prolegis" Elżbieta Linowska.
                </li>
                <li>
                  Pani/Pana dane osobowe przechowywane będą przez okres 6 lat.
                </li>
                <li>
                  Posiada Pani/Pan prawo do żądania od administratora dostępu do
                  danych osobowych, prawo do ich sprostowania, usunięcia lub
                  ograniczenia przetwarzania oraz prawo do przenoszenia danych.
                </li>
                <li>
                  Ma Pani/Pan prawo wniesienia skargi do organu nadzorczego.
                </li>
                <li>
                  Podanie danych osobowych jest dobrowolne, jednakże odmowa
                  podania danych może skutkować odmową realizacji zamówienia.
                </li>
              </ol>
              Administrator danych osobowych ODN "Prolegis" Elżbieta Linowska
            </Text>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
};

export default ContactPage;
